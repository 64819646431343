<template>
	<div :class="themeClass" class="MoneyDash-Box padding-lr-13 margin-t-20">
		<div class="title font-size18 font-color-333 font-weight700">现金钱包</div>
		<div class="flex-row" style="justify-content: space-between;align-items: center;position: relative;">
			<div class="card font-color-fff flex-column-align-center">
				<div class="font-size15 font-weight700 margin-t-25">{{getUserInfo.customerName}}</div>
				<div class="font-size12 margin-t-10">当前余额</div>
				<div class="font-size22 font-weight700">¥ {{moneyDashWalletInfo.fEndBalance | NumFormat}}</div>
				<div class="margin-t-17 flex-row">
					<div class='button-box-TX flex-row-center-center margin-r-10 pointer' @click="toWithdrawal()">
						提现
					</div>
					<div class='button-box-CZ flex-row-center-center pointer' @click="toRecharge()">
						充值
					</div>
				</div>
			</div>
			<div class="rightCard font-color-fff" style="position: relative;">
					<!-- <div class="aaaaa" style="position: absolute;top: 0;left:0;transform: scaleX(-1);"></div> -->
						<!--左侧logo图-->
						<div style="z-index: 999;margin-left: 31px;">
							<img style="width: 120px;height: 109px;" src="../../assets/u442.png"></img>
						</div>
						<!--右侧提示语-->
						<div class="rightCardSize" style="z-index: 999;color:#1e1e1e">
							<div style=" color:#E91D2C;font-size: 20px;font-weight: 700;letter-spacing: 2px;">温馨提示：</div>
							<div style=" color:#6D6D6D;font-size: 16px;letter-spacing: 2px;font-weight: 500; ">现金钱包仅用于自营店<br/>
							<div style="margin-top: -4px !important;">商品下单支付使用</div></div>
						</div>
			</div>
		</div>
		<!--上传文件的弹框-->
		<el-dialog v-if='showUploaderModel' title="下载/上传退款申请" top="5vh" :visible.sync="showUploaderModel" :close-on-click-modal="false"   width="30%" :before-close="updhandleClose">
			
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="form" :rules="rules" :model="uploadforms" :label-width="formLabelWidth" label-position="left">
					<el-form-item label="提现单号" prop="fRefundBillNumber" style="position: relative;">
						<el-input v-model="uploadforms.fRefundBillNumber" disabled></el-input>
					</el-form-item>
					<el-form-item label="提现金额" prop="fRefundGoodsMoney" style="position: relative;">
						<el-input v-model="uploadforms.fRefundGoodsMoney" disabled></el-input>
					</el-form-item>
					<div v-for="(item, index) in imgObj" :key="index">
						<el-form-item :label="item.rule.fRuleName"  :class="item.rule.fIsNecessary==0 ? '': 'isNecessary'" prop="fRefundGoodsMoney" style="position: relative;height: 210px;overflow: hidden;">
							<div class="flex-row">
									<div class="form-box-in">
										<div style="width: 150px;height: 150px;overflow: hidden;">
											<el-upload
											  :action="actionback"
											  list-type="picture-card"
											  :on-remove="handleRemove"
											  :on-success="handlesuceess"
											  :before-upload='beforeupload'
											  :data="uploadToken[index]"
											  :multiple="false" 
											  :limit="1"
											  :accept="item.rule.fFileType"
											  :disabled="isshow"
											  >
											  <i class="el-icon-plus" @click="handlebeforeupload(item.rule)"></i>
											</el-upload>
										</div>
		
									<div data-v-4d9d131f="" class="font-color-FF9900 font-size12">*提示：仅支持上传{{item.rule.fFileType}}格式文件；</div>
								</div>
							</div>
						</el-form-item>
					</div>
					<div data-v-4d9d131f="" class="font-color-FF9900 font-size12 margin-b-10">请将退款申请函及收据原件寄至:{{addressshow}} 电话:{{phoneshow}}</div>
					<el-form-item label="" style="text-align: right;">
						  <div @click="getonDownload" style="padding: 0px 6px ; border: 1px #eee solid;text-align: center;display: inline-block;font-size: 12px;height: 32px;line-height: 32px;border-radius: 5px;background-color: #409EFF;color: #fff;cursor: pointer;">下载申请函模板</div>
					</el-form-item>
					
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updhandleClose">取 消</el-button>
				<el-button type="primary" @click="subforms">确 定</el-button>
			</span>
		</el-dialog>
		
		
		
		<div v-if="getUserInfo.fCommerceTypeID == 1"  class="width-fill tip-box margin-t-10 flex-row-space-between-center">
			<div class="flex-row-align-center">
				<div class="margin-l-13">现金钱包已绑卡</div>
				<div class="margin-rl-5 font-weight700">{{cpcnBankCardNumber}}</div>
				<div>张 </div>
			</div>
			<div class="pointer margin-r-10" @click="toMyBankCard">{{cpcnBankCardNumber==0?'绑定银行卡 >':'查看银行卡 >'}}</div>
		</div>
		<div>
			<el-select v-model="checkedDate" placeholder="时间选择" clearable @change="dateChange" style="margin-top: 15px;" @clear="clearDate"	>
			    <el-option
			      v-for="item in TimeArr"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
			<el-select v-if="tabSelect==0" v-model="fPlyingStatus" clearable placeholder="充值状态" @change="handleCurrentChange(1)" @clear="handleCurrentChange(1)" style="margin-top: 15px; margin-left: 15px;"	>
			    <el-option
			      v-for="item in fPlyingStatusList"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
			<el-select v-if="tabSelect===1" v-model="fStatus" clearable placeholder="提现状态" @change="handleCurrentChanget(1)" @clear="handleCurrentChanget(1)" style="margin-top: 15px; margin-left: 15px;"	>
			    <el-option
			      v-for="item in fStatusList"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
			</el-select>
			<div class="tab text-center margin-t-10 flex-row-space-between">
				<div class="flex-row">
					<div class="tab-item" :class="tabSelect==0?'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2':''" @click="tabClick(0)">充值明细</div>
					<div class="tab-item" :class="tabSelect==1?'bg-fff color-theme border-lr-F2F2F2 border-t-f2f2f2':''" @click="tabClick(1)">提现明细</div>
				</div>
			</div>
			<div class="bill" v-if="tabSelect==0">
				<div class="bill-item border-b-F2F2F2" v-for="(item,index) in cashdetails" :key="index">
					<div  >
						<div class="flex-row-space-between lh24">
							<div class="font-size14">{{item.fRemark}}(单号:&nbsp;{{item.fGatheringBillNumber}})
								<span :class="item.fPlyingStatusName === '支付成功' ? 'successBgc' : item.fPlyingStatusName === '支付失败' ? 'errorBgc' : 'bg-FF9900' " class="audit text-center margin-l-10 font-color-fff font-size12 padding-lr-5">{{ item.fPlyingStatusName}}</span>
							</div>
							<div class="font-size14">¥{{item.fSumMoney|NumFormat}}</div>
						</div>
						<div class="flex-row-space-between lh24">
							<div class="font-color-999 font-size12">{{item.fCreateTime}}</div>
							<!-- <div>{{item.fIsdelete}}</div>
							<div>{{item.fGatheringBillID}}</div> -->
							<div v-if="item.fIsdelete === 1">
								<div @click="deletes(item)" style="padding: 0px 6px;font-size: 12px;cursor: pointer;background-color: #409EFF;color: #fff;">删除</div>
							</div>
						</div>
					</div>
					
				</div>
				<div class="pagination flex-row-center-flex-end ">
					<el-pagination background layout="total,prev, pager, next" :page-size="maxResultCount" :total="total"
					 @current-change="handleCurrentChange"></el-pagination>
				</div>
			</div>
			<div class="bill" v-if="tabSelect===1">
				<div class="bill-item border-b-F2F2F2" v-for="(item,index) in detailData" :key="index">
					<div class="flex-row-space-between lh24">
						<div class="font-size14">现金钱包提现(单号:&nbsp;{{item.fRefundBillNumber}})
							<span :class="item.fStatusName === '提现成功' ? 'successBgc' : item.fStatusName === '提现失败' ? 'errorBgc' : 'bg-FF9900'" class="audit text-center margin-l-10 font-color-fff font-size12 padding-lr-5">{{ item.fStatusName}}</span>
						</div>
						<div class="font-size14">¥{{item.fRefundMoney|NumFormat}}</div>
					</div>
					<div class="flex-row-space-between lh24">
						<div>
							<div class="font-color-999 font-size12"><span class="font-color-999 font-size12 ">{{item.fCreateTime}}</span></div>
						</div>
						<div v-if="item.fIsNeedUpload == 1">
							<div @click="openUploaders(item)" style="padding: 0px 6px;font-size: 12px;cursor: pointer;background-color: #409EFF;color: #fff;" class="font-size14">下载/上传退款申请</div>
						</div>
						<div v-if="item.fIsNeedUpload == 2">
							<div style="padding: 0px 6px;font-size: 12px;cursor: pointer;background-color: #409EFF;color: #fff;" class="font-size14"  @click="showImgClick(item.fAnnex)" >查看附件</div>
						</div>
						
					</div>
				</div>
				<div class="pagination flex-row-center-flex-end">
					<el-pagination background layout="total,prev, pager, next" :page-size="pagesizet" :total="totalt"
					 @current-change="handleCurrentChanget"></el-pagination>
				</div>
			</div>
			<div class="flex-row-center-center" v-if="cashdetails.length == 0">
				<svg-icon icon-class="NoRightsAndInterests" />
			</div>
		</div>
		<ImageViewer v-if="showImg" :srcList="showObj" :index="0" @close="showImg = false"></ImageViewer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	// import UploadImgList from  '@/components/upload/UploadImgList.vue'
	import pagination from '@/components/pagination/pagination.vue'
	import ImageViewer from '@/components/upload/ImageViewer.vue'
	import uploadFile from "@/utils/uploadFile";
	export default {
		data() {
			return {
				showObj: [], //点击放大的图片路径
				showImg: false, //图片是否显示
				fIsNecessaryData: [],//必传数据
				 dialogImageUrl: '',
				 uploadDir:"Web/SupplyDemand",
				 uploadFileAshx:"",
				currentPaget: 1, //当前页
				pagesizet: 6, //每页显示10条数据			
				totalt: 0, //总数据
				page: 1,
				maxResultCount:6,
				total: 0, //总数据
				cashdetails: [],
				tabSelect: 0,
				currentPage: 1, //当前页
				pagesize: 10, //每页显示10条数据
				detailData: [],
				userinfo: {},
				moneyDashWalletInfo: {}, //现金钱包信息
				cpcnBankCard: null, //现金钱包银行卡信息
				cpcnBankCardNumber: 0, //现金钱包银行卡信息
				
				formLabelWidth: '100px',
				// 表单绑定数据
				uploadforms:{
					fRefundGoodsMoney:"", //提现金额
					fRefundBillNumber:"",// 提现编号
				},
				imgObj: [], // 图片规则
				isshow:false,
				list: [], //编辑回传的图片
				uploadToken:[],
				imglistback: [], //上传整理后的图片数组
				editlist: [], //编辑时的图片列表
				fAnnexTypeIDData: [],
				// 表单验证规则
				rules: {
					fSupplierName: [{
						required: true,
						message: '供应商不能为空',
						trigger: 'blur,change'
					}],
					KeepDate: [{
						required: true,
						message: '记账日期不能为空',
						trigger: 'blur'
					}],
					upddate: [{
						required: true,
						message: '年月不能为空',
						trigger: 'blur'
					}],
					fInvoiceClassName: [{
						required: true,
						message: '发票分类不能为空',
						trigger: 'blur'
					}],
					modeltype: [{
						required: true,
						message: '发票类型不能为空',
						trigger: 'blur'
					}],
					fDepotNames: [{
						required: true,
						message: '仓库不能为空',
						trigger: 'blur'
					}],
				},
				//弹框数据
				showUploaderModel:false,
				filesetID:"",
				actionback:"",
				annexServerUrl: '',
				
				
				// 上传数据
				
				ruledom:{}, //每次需要上传组织的数据的rule
				
				
				accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
				
				addressshow:"",
				phoneshow:"",
				
				TimeArr: [], // 时间搜索框
				checkedDate: '',
				fBeginDate:"",
				fEndDate: "",
				fPlyingStatusList: [ // 充值状态
					{
						value:0,
						label: "未支付",
					},
					{
						value:1,
						label: "支付中",
					},
					{
						value:2,
						label: "支付成功",
					},
					{
						value:3,
						label: "支付失败",
					},
				],
				fPlyingStatus: '',
				fStatusList: [ // 提现状态
					{
						value:"不同意",
						label: "不同意",
					},
					{
						value:"审核中",
						label: "审核中",
					},
					{
						value:"提现中",
						label: "提现中",
					},
					{
						value:"提现成功",
						label: "提现成功",
					},
					{
						value:"提现失败",
						label: "提现失败",
					},
				],
				fStatus: ''
			};
		},
		components: {
			pagination,
			ImageViewer
			// UploadImgList
		},
		computed: {
			current: {
				get() {
					return this.currentPage
				},
				set() {}
			},
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			//今年的第一天和最后一天
			let year1 = new Date().getFullYear();
			let yearFirstday1 = new Date(year1, 0, 1);
			let yearFirstday1End = new Date(year1, 11, 31);
			//去年的第一天和最后一天
			let year2 = new Date().getFullYear() -1;
			let yearFirstday2 = new Date(year2, 0, 1);
			let yearFirstday2End = new Date(year2, 11, 31);
			//前年的第一天和最后一天
			let year3 = new Date().getFullYear() -2;
			let yearFirstday3 = new Date(year3, 0, 1);
			let yearFirstday3End = new Date(year3, 11, 31);
			//获取前年之前所有的年份时间
			let yearFirstday4End = new Date(year3-1, 11, 31);
			let newarray = [] //时间选择的数组
			for(let i=0;i<5;i++){
				if(i==0){
					newarray.push({
						value:0,
						label: "近3个月",
						fBeginDate:new Date()- 3600 * 1000 * 24 * 90,
						fEndDate:new Date()
					})
				}else if(i==1){
					newarray.push({
						value: 1,
						label: "今年",
						fBeginDate:yearFirstday1,
						fEndDate:yearFirstday1End
					})
				}else if(i==2){
					newarray.push({
						value: 2,
						label: (new Date().getFullYear()-1)  + "年",
						fBeginDate:yearFirstday2,
						fEndDate:yearFirstday2End
					})
				}else if(i==3){
					newarray.push({
						value:3,
						label: (new Date().getFullYear()-2)  + "年",
						fBeginDate:yearFirstday3,
						fEndDate:yearFirstday3End
					})
				}else if(i==4){
					newarray.push({
						value:4,
						label: (new Date().getFullYear()-2)  + "年之前",
						fBeginDate:"",
						fEndDate:yearFirstday4End
					})
				}
			}
			this.TimeArr = JSON.parse(JSON.stringify(newarray))
			this.getImgUrl();
			
			this.getRechargeDetails() //获取充值明细
			this.getWithdrawalDetails() //获取提现明细
			this.getMoneyDashBalance() //获取现金钱包余额
			this.getCpcnPhysicalAccount() //获取中金银行卡信息
		},
		methods: {
			// 时间筛选
			dateChange(val) {
				console.log(val)
				if (val === '') {
					return
				} else {
					for(let item of this.TimeArr){
						if(item.value == val){
							this.fBeginDate = item.fBeginDate
							this.fEndDate =   item.fEndDate
							if (this.tabSelect == 1) {
								this.handleCurrentChanget(1)
							} else if (this.tabSelect == 0) {
								this.handleCurrentChange(1)
							}
						}
					}
				}
			},
			clearDate() {
				this.fBeginDate = ''
				this.fEndDate = ''
				if (this.tabSelect == 1) {
					this.handleCurrentChanget(1)
				} else if (this.tabSelect == 0) {
					this.handleCurrentChange(1)()
				}
			},
			//查看附件图片
			showImgClick(val) {
				let arrayFul = []
				if(val.length !=0){
					for(let item of val){
						arrayFul.push(item.fullFPath)
					}
				}
				this.showObj = arrayFul;
				this.showImg = true;
			},
			getaddress(){
				this.ApiRequestPostNOMess(
					"/api/mall/ebshop/baseinfo/get-self-support-shop"
				).then(
					(result) => {
						console.log("result返回的config结果", result)
						this.addressshow = result.obj.fAddress
						this.phoneshow = result.obj.fcsPhone
					},
					(rej) => {}
				);
			},
			handlebeforeupload(e){
				if(this.isshow == true){
					return
				}
				this.ruledom={
					fAnnexTypeID: e.fAnnexTypeID,
					fBillTypeID: e.fBillTypeID,
					fRuleID: e.fRuleID,
					frv: e.frv
				}

			},
			getImgUrl() {
				//上传图片前缀
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/para-value/get-config"
				).then(
					(result) => {
						console.log("result返回的config结果", result)
						this.annexServerUrl = result.obj.annexServerUrl
						this.uploadFileAshx = result.obj.annexServerUrl + 'upload';
						
						this.uploadActionURL()
					},
					(rej) => {}
				);
			},
			uploadActionURL() {
				this.actionback =  uploadFile.makeUploadActionURL(this.uploadDir, {
					ashx: this.uploadFileAshx,
				});
				console.log("返回的连接",this.actionback)
			},
			handleRemove(file, fileList) {
			        console.log(file, fileList);
					console.log("删除图片数据",file)
					console.log("删除之前的数据",this.imglistback)
					for(var i =0; i< this.imglistback.length;i++){
						if(this.imglistback[i].uid == file.uid){
							this.imglistback.splice(i,1)
						}
					}
					console.log("删除完的数据",this.imglistback)
			      },
			// 弹框关闭
			updhandleClose(){
				this.showUploaderModel = false
			},
			//删除
			deletes(item) {
				// fDeliveryOrderID  派车订单ID
				this.$confirm('确定要删除此充值单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							setTimeout(() => {
								done();
								setTimeout(() => {
									instance.confirmButtonLoading = false;
								}, 300);
							}, 1000);
						} else {
							done();
						}
					}
				}).then(() => {
					let arr = []
					arr.push({
						fGatheringBillID:item.fGatheringBillID
					})
					this.ApiRequestPost("api/mall/ebsale/gathering-bill/is-delete-list", arr)
					.then(res => {
						this.$emit("queryList")
					}).catch(err => {
						this.$emit("queryList")
					})
					this.getRechargeDetails()
				}).catch(() => {});
			},
			// 打开弹框
			openUploaders(id){
				this.getaddress()
				let params ={
					id:id.fRefundBillID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/get',params).then(
					result => {
						console.log(result ,"获取接口2 数据")
						// this.uploadforms.name = result.
						this.uploadforms.fRefundGoodsMoney = result.obj.fRefundGoodsMoney //提现金额
						this.uploadforms.fRefundBillNumber = result.obj.fRefundBillNumber // 提现编号
						this.filesetID = id
						this.getuploaderrules(id)
					},
					rej => {}
				);
								this.imglistback = []
				// this.showUploaderModel = true  //注释掉打开弹框的变量
				let obj = {
					successType: 'TX',
					examine:"",
					amount: id.fApplyMoney,
					billNumber: id.fRefundBillNumber,
					reID: id.fRefundBillID,
					fSettleModeID:id.fSettleModeID,
					type: 'XJ',
					fBillTypeID: id.fBillTypeID,
				}
				this.$router.replace({
					path: '/businessme/RechSuccess',
					query: {
						// data: encodeURIComponent(JSON.stringify(obj))
						data: JSON.stringify(obj),
					}
				})
			},
			beforeupload(){
						this.isshow = true
			},
			// 文件上传成功的
			handlesuceess(response, file, fileList){
				console.log("上传成功的回调函数response",response)
				console.log("上传成功的回调函数file",file)
				console.log("上传成功的回调函数fileList",fileList)
				console.log("现在的ruleID",this.ruledom)
				this.isshow = false
				this.ruledom.fPath = response.fileName
				this.ruledom.fSourceFileName = file.name
				this.ruledom.fFileExName = file.name.split('.')[0]
				this.ruledom.uid = file.uid
				this.imglistback.push(this.ruledom)
				console.log(this.imglistback,'查看里面的数据')
				
			},
			// 确定
			subforms(){
				// if(this.imglistback.length == 0){
				// 	this.$message.error("请上传图片")
				// 	return
				// }
				if (this.fIsNecessaryData.length > 0) {
					var falg = true
					this.fIsNecessaryData.forEach(item => {
						if (!this.imglistback[item.index]) {
							falg = false
						}
					})
					if(falg == false){
						this.$message.error("请上传图片")
						return
					}
				}
				let arr = []
				for(let item of this.imglistback){
					arr.push({
						fRuleID:item.fRuleID,
						fAnnexTypeID:item.fAnnexTypeID,
						fPath:item.fPath,
						fSourceFileName:item.fSourceFileName,
						fFileExName:item.fFileExName,
					})
				}
				let params = {
					 fRefundBillID: this.filesetID.fRefundBillID,
					 annexList: arr
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/create-annex-offline-refund-bill',params).then(
					result => {
						console.log(result ,"获取接口5 数据")
						if(result.code =  "HengHeCode1000"){
							this.showUploaderModel = false
							this.$message.success("上传成功")
							this.getWithdrawalDetails()
							this.uploadforms.fRefundGoodsMoney = ""
							this.uploadforms.fRefundBillNumber = ""
							this.imglistback = []
						}
					},
					rej => {}
				);
			},
			// api/mall/ebsale/refund-bill/print-refund-letter
			//获取图片上传规则
			getuploaderrules(id){
				let params = {
					fRefundBillID: id.fRefundBillID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/get-annexrule-offline-refund-bill',params).then(
					result => {
						
						console.log(result, '获取图片上传规则');
						console.log(result.obj)
						this.imgObj = result.obj
						this.imgObj.forEach((item, index) => {
							this.uploadToken.push({token:item.token})
							this.fAnnexTypeIDData.push(item.rule)
						})
						this.fIsNecessaryData = []
						for (let i = 0; i < this.imgObj.length; i++) {
							if (this.imgObj[i].rule.fIsNecessary == 1) {
								this.fIsNecessaryData.push({
									fRuleID: this.imgObj[i].rule.fRuleID,
									fRuleName: this.imgObj[i].rule.fRuleName,
									index: i
								})
							}
						}
						console.log('获取图片上传规则')
					},
					rej => {}
				);
			},
			 // 模板下载
			getonDownload(){
				let params = {
					fRefundBillID:this.filesetID.fRefundBillID
				}
				this.ApiRequestPost('api/mall/ebsale/refund-bill/print-refund-letter',params).then(
					result => {
						console.log("下载元素",result)
						window.open(result.obj)
					},
					rej => {}
				);
			},
			//图片返回并处理
			// goodsPictures(data) {
			// 	console.log('图片返回数组列表', data)
			// 	if (data.length != 0) {
			// 		let arry1 = [];
			// 		for (let i in this.list) {
			// 			let listItem = this.list[i][0];
			// 			if (listItem != null && listItem != undefined) {
			// 				for (let j in this.fAnnexTypeIDData) {
			// 					if (i == j) {
			// 						listItem.fAnnexTypeID = this.fAnnexTypeIDData[j].fAnnexTypeID;
			// 						listItem.fIsNecessary = this.fAnnexTypeIDData[j].fIsNecessary;
			// 						// listItem.fFileExName = listItem.fPicturePath.substring(
			// 						// 	listItem.fPicturePath.lastIndexOf(".") + 1
			// 						// );
			// 					}
			// 				}
			// 				arry1.push(listItem);
			// 			}
			// 		}
			// 		console.log('图片整理后列表', this.imglistback)
			// 		this.imglistback = arry1;
			// 	}
			// 	console.log('图片列表', this.imglistback)
			
			// },
			//移除图片
			// removeImgListchange(id) {
				
			// 	this.imglistback = this.imglistback.filter((item) => item.url !== id);
			// 	console.log(this.imglistback,"删除返回的数据列表")
				
			// },
			
			//获取现金钱包余额
			getMoneyDashBalance() {
				this.ApiRequestPost('api/mall/ebbalance/customer-cash/get-my-cash').then(
					result => {
						this.moneyDashWalletInfo = result.obj[0] ? result.obj[0] : 0;
						console.log(result.obj[0], '获取现金钱包余额');
					},
					rej => {}
				);
			},
			//中金绑定银行卡查询
			getCpcnPhysicalAccount() {
				this.ApiRequestPost('api/mall/ebcustomer/bank-account/get-cpcn-physical-account-list').then(
					result => {
						console.log(result.obj, '中金绑定银行卡查询');
						this.cpcnBankCard = result.obj[0]
						this.cpcnBankCardNumber = result.obj.length
					},
					rej => {}
				);
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val
				this.fStatus = ''
				this.fPlyingStatus = ''
				if (this.tabSelect == 0) {
					this.getRechargeDetails()
				} else {
					this.getWithdrawalDetails()
				}
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getRechargeDetails();
			},
			handleCurrentChanget(val) {
				this.currentPaget = val;
				this.getWithdrawalDetails();
			},
			
			// 获取充值明细 
			getRechargeDetails() {
				console.log(this.fPlyingStatus)
				this.ApiRequestPostNOMess('api/mall/ebsale/gathering-bill/get-customer-cash-charge', {
					fPlyingStatuss: this.fPlyingStatus === '' ? [] : [this.fPlyingStatus*1],
					fBeginDate: this.fBeginDate==""?"":this.getDate(this.fBeginDate),
					fEndDate: this.fEndDate==""?"":this.getDate(this.fEndDate),
					filter: "",
					maxResultCount: this.maxResultCount,
					skipCount: (this.page - 1) * this.maxResultCount,
				}).then(result => {
					this.cashdetails = result.obj.items
					for (let item of result.obj.items) {
						item.fCreateTime = this.getTime(item.fCreateTime);
					}
					this.total = result.obj.totalCount
				}, rej => {})
			},
			// 获取提现明细
			getWithdrawalDetails() {
				console.log(this.fStatus)
				this.ApiRequestPostNOMess('api/mall/ebsale/refund-bill/get-list-current-customer-wallet', {
					fStatuss: '',
					fStatusNames: this.fStatus === '' ? [] : [this.fStatus],
					fBeginDate: this.fBeginDate==""?"":this.getDate(this.fBeginDate),
					fEndDate: this.fEndDate==""?"":this.getDate(this.fEndDate),
					maxResultCount: this.pagesizet,
					filter: "",
					skipCount: (this.currentPaget - 1) * this.pagesizet,
					
				}).then(result => {
					
					this.detailData = result.obj.items;
					
					for (let item of result.obj.items) {
						item.fCreateTime = this.getTime(item.fCreateTime);
					}
					this.totalt = result.obj.totalCount
				}, rej => {})
			},
			// 提现跳转
			toWithdrawal() {
				if (this.moneyDashWalletInfo.fEndBalance == 0) {
					this.$message({
						message: '亲,您当前的余额为零哦~',
						type: 'success'
					});
					return
				}
				let obj = {
					type: 'withdrawal',
					walletType: 'XJ',
				}
				this.$router.push({
					path: '/businessme/RechargeWallet',
					query: {
						data: encodeURIComponent(JSON.stringify(obj)),
						id:this.filesetID
					}
				});
			},
			// 充值跳转
			toRecharge() {
				if (this.cpcnBankCardNumber == 0 && this.getUserInfo.fCommerceTypeID == 1) {
					this.$message({
						message: '亲,您还未绑定充值卡哦~',
						type: 'success'
					});
					return
				}
				let obj = {
					type: 'recharge',
					walletType: 'XJ',
				}
				this.$router.push({
					path: '/businessme/RechargeWallet',
					query: {
						data: encodeURIComponent(JSON.stringify(obj))
					}
				});
			},
			//跳转我的银行卡
			toMyBankCard() {
				if (this.cpcnBankCardNumber == 0) { //没绑卡
					this.$router.push({
						path: '/businessme/individualUsersBindingCard',
						query: {
							title: '绑定银行卡',
							type: 1
						}
					}) //跳转个人用户绑卡
				} else { //已绑卡
					console.log('跳转我的银行卡')
					this.$router.push({
						path: '/businessme/WalletBankCard',
						query: '',
					});
				}

			},
		},
		filters: {
			//格式化银行账户
			BankCardFormat: function(value) {
				if (value && value.length > 8) {
					return (`${value.substring(0, 4)}` + "<span style='margin:0px 5px;letter-spacing:4.5px;font-weight:700'>" +
						`${"*".repeat(value.length - 8).replace(/(.{4})/g, `
						$1 `)}` + "</span>" + `${value.slice(-4)}`);
				}
				return value;
			},
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-4)}`
			}
		},
	}
</script>


<style lang="scss" scoped="scoped">
	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.button-box-TX {
		width: 118px;
		height: 38px;
		border-radius: 2px;
		border: 1px solid #FFFFFF;
		user-select: none;
	}

	.button-box-CZ {
		width: 120px;
		height: 40px;
		border-radius: 2px;
		color: #4180dc;
		background-color: #FFFFFF;
		user-select: none;
	}

	.flex-row-Delete {
		width: 57px;
		height: 23px;
		border-radius: 10px;
		text-align: center;
		line-height: 23px;
		// margin-left:95px;
	}

	.margin-l-6 {
		margin-left: 6px;
	}

	.border-b-F2F2F2 {
		border-bottom: 1px solid #f2f2f2;
	}

	.border-t-f2f2f2 {
		border-top: 1px solid #f2f2f2;
	}

	.lh24 {
		height: 24px;
		line-height: 24px;
	}

	.MoneyDash-Box {
		width: 100%;
		height: calc(100% - 10px);
		// background-color: #ff3040;
		background-color: #FFFFFF;
	}

	.title {
		overflow: hidden; //解决盒子塌陷
		margin: 8px 0;
		height: 20px;
		line-height: 20px;
	}

	.card {
		flex:1;
		position: relative;
		height: 175px;
		// background-color: rgba(2, 85, 208, 0.75);
		border-radius: 5px 0px 0px 5px;
		box-sizing: border-box;
		overflow: hidden;
		background-image: url(../../assets/qbbg.png);
		background-size: 100% 100%;
		// .bg {
		// 	position: absolute;
		// 	width: 440px;
		// 	height: 175px;
		// 	z-index: -1;
		// 	// opacity: 0.3;
		// }

		.shopName {
			height: 15px;
			line-height: 15px;
			margin-bottom: 5px;
		}

		.money {
			position: absolute;
			top: 25px;
		}

		.cash-out {}

		.card-btn {
			width: 150px;
			margin-top: 7px;
			float: right;
			height: 24px;
			line-height: 24px;
			border: 1px solid #fff;
			position: absolute;
			bottom: 20px;
			left: 280px;
			cursor: pointer;

			.btn1,
			.btn2 {
				display: inline-block;
				width: 75px;
				height: 25px;
			}
		}
	}
	.rightCard{
		flex:1;
		height: 175px;
		border-radius: 0px 5px 5px 0px;
		// background-image: url(../../assets/qbbg.png);
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		align-items: center;
		opacity: 1;
		z-index:10;
	}
	.aaaaa{
		width: 100%;
		height: 100%;
		background-image: url(../../assets/qbbg.png);
		background-size: 100% 100%;
	}
	.rightCardSize{
		width: 203px;
		font-size: 14px;
		line-height: 24px;
		margin-left: 38px;
	}
	.tab {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background-color: #F8F9FE;

		.tab-item {
			width: 108px;
			height: 40px;
		}

		.mycard {
			float: right;
			width: 110px;
			height: 30px;
			line-height: 30px;
			margin: auto 13px;
		}
	}

	.bill {
		.bill-item {
			height: 72px;
			padding: 10px 13px;
			box-sizing: border-box;
		}
	}

	.bankcard {
		width: 292px;
		height: 105px;
		opacity: 1;
		background: #e1efff;
		overflow: hidden;
		margin-left: 10px;

		.card-top {
			width: 100%;
			height: 54px;
			opacity: 1;
			background: #f8f9fe;
			box-sizing: border-box;
			padding: 15px;
		}

		.bank {
			height: 19px;
			line-height: 19px;

			.bank-icon {
				width: 29px;
				height: 18px;
			}

			.bank-name {
				margin-left: 5px;
			}

			.card-type {
				width: 48px;
				height: 17px;
				line-height: 17px;
				margin-left: 7px;
				border-radius: 2px;
			}

			.bg-33b1c0 {
				background: #33b1c0;
			}
		}

		.cardID {
			height: 24px;
			// margin-top: 12px;
		}

		.userinfo {
			padding-left: 15px;
		}

		.h51 {
			height: 51px;
			line-height: 51px;
		}

		.unbundling {
			height: 16px;
			line-height: 16px;
		}

	}

	.tip-box {
		height: 50px;
		background-color: #ffe99d;
		border-radius: 5px;
	}
	
	/deep/ .el-upload--picture-card{
		position:relative !important;
	}
	.el-upload--picture-card i{
		font-size: 28px;
		    color: #8c939d;
		    width: 100%;
		    height: 100%;
		    position: absolute;
		    top: 0;
		    left: 0;
		    line-height: 136px;
	}
	.isNecessary:before {
		content: "*";
		position: absolute;
		color: red;
		top: 0px;
		left: 80px;
		font-size: 16px;
		font-weight: 700;
	}
	.successBgc {
		background-color: #19c500;
	}
	.errorBgc {
		background-color: #ccc;
	}
	/deep/.el-input__icon {
		line-height: 0px;
	}
</style>
